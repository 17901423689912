<template>
    <div class="pc reserva">

        <!-- Spiner -->
        <div v-if="isPreLoad" class="preload">
            <div class="preload-wrapper"></div>
            <div class="preload-dots">
                <div class="hollow-dots-spinner">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        </div>
        <!--Fin spiner -->

        <NavbarGuest :translator="translator" :user="user" :booking="booking" />

        <div class="container mt-4">
            <h1 v-cloak class="mb-4">
                <span v-if="user.user_type === 'guest'">
                    <i class="fal fa-key pr-1"></i>
                </span>
                {{ translator.clau }}
            </h1>


            <div v-if="smartKeys != '' || booking.key_box_code != ''">

                <!-- Missatge de que han de tenir signat el contracte i la reserva pagada -->
                <div v-if="
                    (
                        (booking.mandatory_guests_at_check_in === 'true' && !booking._registered_adults_only_completed)
                    ) ||
                    (
                        (booking.mandatory_signatures_contract === 'true' && !this.controlAdultWithSign === 'false')
                    ) ||


                    (
                        (booking.mandatory_signature_at_check_in === 'true' && booking.signed_contract === 'false')
                    ) ||


                    (
                        (booking.mandatory_guests_contract === 'true' && !isFilledFields)
                    ) ||
                    (
                        booking.mandatory_guests_at_check_in === 'true' &&
                        (
                            (booking.visible_selfie === 'true' && !is_allSelfiGuests) ||
                            (booking.visible_passport === 'true' && !is_allPhotoDocuments) ||
                            (booking.visible_document === 'true' && !is_allAnversoDocument)
                        )
                    ) ||
                    (
                        booking.mandatory_payment_contract === 'true' && (!booking.paymentCompleted ||
                            (booking.mandatory_deposit_guarantee_at_check_in === 'true' && (bookingPayments.deposit_paid === 'false' && bookingPayments.security_deposit != '0.00'))
                        ) ||
                        (booking.mandatory_signatures_contract == true && !booking.contractCompleted))

                " class="alert alert-warning" role="alert">



                    <i class="far fa-exclamation-circle"></i>
                    {{ translator.faltenregistresclaus }}:

                    <div class="d-flex flex-column mt-2">

                        <span class="font-weight-bold" v-if="booking.customer_first_name == '' ||
                            booking.customer_last_name == '' ||
                            booking.customer_email == '' ||
                            booking.customer_phone == '' ||
                            booking.customer_address == '' ||
                            booking.customer_city == '' ||
                            booking.customer_zip == '' ||
                            booking.customer_country == '' ||
                            booking.customer_tin == '' ||
                            booking.customer_nationality == ''
                        ">
                            {{ translator.dadespersonals }}
                        </span>

                        <span class="font-weight-bold" v-if="(
                            (booking.mandatory_guests_at_check_in === 'true' && !booking._registered_adults_only_completed)
                        ) ||
                            (
                                (booking.mandatory_signatures_contract === 'true' && !this.controlAdultWithSign)
                            ) ||
                            (
                                (booking.mandatory_guests_contract === 'true' && !isFilledFields)
                            ) ||
                            (
                                booking.mandatory_guests_at_check_in == 'true' &&
                                (
                                    (booking.visible_selfie === 'true' && !is_allSelfiGuests) ||
                                    (booking.visible_passport === 'true' && !is_allPhotoDocuments) ||
                                    (booking.visible_document === 'true' && !is_allAnversoDocument)
                                )
                            )">

                            {{ translator.hostes }}
                        </span>

                        <span class="font-weight-bold"
                            v-if="booking.mandatory_payment_contract === 'true' && !booking.paymentCompleted || (booking.mandatory_deposit_guarantee_at_check_in === 'true' && (bookingPayments.deposit_paid === 'false' && bookingPayments.security_deposit != '0.00'))">
                            {{ translator.pagament }}
                        </span>

                        <span class="font-weight-bold"
                            v-if="((booking.mandatory_signatures_contract == true && !booking.contractCompleted) || (booking.mandatory_signature_at_check_in === 'true' && booking.signed_contract === 'false'))">
                            {{ translator.contracte }}
                        </span>
                    </div>
                </div>

                <!-- //El contrato debe estar firmado para el check-in
                //Los huéspedes deben estar registrados para el check-in
                //La reserva debe estar pagada para el check-in && fianza pagada
                //Firmas obligatorias para firmar el contrato
                //Registro de huéspedes obligatorio para firmar el contrato
                //Imágen del documento de identidad visible
                //Selfie y foto del documento de identidad
                //Control de fechas entrada y salida -->

                <!-- <pre>{{ ((booking.mandatory_signature_at_check_in == 'true' && booking.signed_contract == 'true') || booking.mandatory_signature_at_check_in == 'false') }}</pre>
                <pre>{{ ((booking.mandatory_guests_at_check_in == 'true' && booking._registered_adults_only_completed) || booking.mandatory_guests_at_check_in == 'false') }}</pre>
                <pre>{{ ((booking.mandatory_payment_at_check_in == 'true' && parseInt(booking.pending_payment) == 0 && (bookingPayments.deposit_paid == 'true' || parseInt(bookingPayments.security_deposit) == 0)) || booking.mandatory_payment_at_check_in == 'false') }}</pre>
                <pre>{{ ((booking.mandatory_signatures_contract == 'true' && this.controlAdultWithSign) || booking.mandatory_signatures_contract == 'false') }}</pre>
                <pre>{{ ((booking.mandatory_guests_contract == 'true' && isFilledFields) || booking.mandatory_guests_contract == 'false') }}</pre>
                <pre>{{ ((booking.visible_selfie == 'true' && is_allSelfiGuests) || booking.visible_selfie == 'false') }}</pre>
                <pre>{{ ((booking.visible_passport == 'true' && is_allPhotoDocuments) || booking.visible_passport == 'false') }}</pre>
                <pre>{{ ((booking.visible_document == 'true' && is_allAnversoDocument) || booking.visible_document == 'false') }}</pre>
                <pre>{{ ((booking.visible_smart_lock_code_before_arrival_date == 'false' && (is_DateCheckinGreaterThan && is_DateCheckoutSmallerThan)) || booking.visible_smart_lock_code_before_arrival_date == 'true') }}</pre> -->


                <div v-if="((booking.mandatory_signature_at_check_in == 'true' && booking.signed_contract == 'true') || booking.mandatory_signature_at_check_in == 'false') &&
                    ((booking.mandatory_guests_at_check_in == 'true' && booking._registered_adults_only_completed) || booking.mandatory_guests_at_check_in == 'false') &&

                    ((booking.mandatory_payment_contract === 'true' && ((booking.mandatory_payment_at_check_in == 'true' && parseInt(booking.pending_payment) == 0) || booking.mandatory_payment_at_check_in == 'false') &&
                        (booking.mandatory_deposit_guarantee_at_check_in === 'true' && bookingPayments.deposit_paid === 'true') ||
                        booking.mandatory_deposit_guarantee_at_check_in === 'false' || parseInt(bookingPayments.security_deposit) == 0
                    ) || booking.mandatory_payment_contract === 'false') &&
                    ((booking.mandatory_signatures_contract == 'true' && this.controlAdultWithSign) || booking.mandatory_signatures_contract == 'false') &&
                    ((booking.mandatory_guests_contract == 'true' && isFilledFields) || booking.mandatory_guests_contract == 'false') &&
                    (
                        booking.mandatory_guests_at_check_in == 'false' ||
                        (
                            (booking.mandatory_guests_at_check_in === 'true') &&
                            ((booking.visible_selfie === 'true' && is_allSelfiGuests) || booking.visible_selfie === 'false') &&
                            ((booking.visible_passport === 'true' && is_allPhotoDocuments) || booking.visible_passport === 'false') &&
                            ((booking.visible_document === 'true' && is_allAnversoDocument) || booking.visible_document === 'false')
                        )
                    ) &&
                    ((booking.visible_smart_lock_code_before_arrival_date == 'false' && (is_DateCheckinGreaterThan && is_DateCheckoutSmallerThan)) || booking.visible_smart_lock_code_before_arrival_date == 'true')
                ">
                    <!-- Codi d'apertura, un numero o així -->
                    <div v-if="smartKeys.smart_door_lock_key != ''" class="card shadow-sm p-3 mb-3">
                        <p class="mb-0">
                            {{ translator.clauapertura }}
                        </p>

                        <div class="key-code">
                            {{ smartKeys.smart_door_lock_key }}
                        </div>
                    </div>
                    <div v-if="booking.key_box_code != '' && smartKeys.smart_door_lock_key != booking.key_box_code"
                        class="card shadow-sm p-3 mb-3">
                        <p class="mb-0">
                            {{ translator.keyboxcode }}
                        </p>

                        <div class="key-code">
                            {{ booking.key_box_code }}
                        </div>
                    </div>

                    <!-- Butons d'apertura, per obrir directament per la app -->
                    <div v-if="(smartKeys.smart_door_lock_command_1 == 'true' || smartKeys.smart_door_lock_text_1 != '') && (is_DateCheckinGreaterThan && is_DateCheckoutSmallerThan)"
                        class="card shadow-sm p-3 mb-3 align-items-center">
                        <div>
                            <span><b>{{ smartKeys.smart_door_lock_headline_1 }}</b></span>
                            <div v-html="this.smartKeys.smart_door_lock_text_1"></div>
                        </div>

                        <BotonSlider v-if="smartKeys.smart_door_lock_command_1 == 'true'" :id="'btn1'"
                            :translator="translator" :numBooking="user.user_booking" :userId="userId"
                            :name="[`${smartKeys.smart_door_lock_headline_1}`, `${smartKeys.smart_door_lock_label_1}`]"
                            door="1" />
                        <!-- <button v-if="smartKeys.smart_door_lock_command_1 == 'true'" @click="openDoor(1)" class="w-100 btn btn-outline-secondary btn-lg mt-3">
                            <span v-if="smartKeys.smart_door_lock_headline_1 != ''">{{ translator.obrir }} {{ smartKeys.smart_door_lock_headline_1 | toLower }}</span>
                            <span v-else>{{ translator.obrir }} {{ smartKeys.smart_door_lock_label_1 | toLower }}</span>
                        </button> -->
                    </div>

                    <div v-if="(smartKeys.smart_door_lock_command_2 == 'true' || smartKeys.smart_door_lock_text_2 != '') && (is_DateCheckinGreaterThan && is_DateCheckoutSmallerThan)"
                        class="card shadow-sm p-3 mb-3 align-items-center">
                        <div>
                            <span><b>{{ smartKeys.smart_door_lock_headline_2 }}</b></span>
                            <div v-html="this.smartKeys.smart_door_lock_text_2"></div>
                        </div>
                        <BotonSlider v-if="smartKeys.smart_door_lock_command_2 == 'true'" :id="'btn2'"
                            :translator="translator" :numBooking="user.user_booking" :userId="userId"
                            :name="[`${smartKeys.smart_door_lock_headline_2}`, `${smartKeys.smart_door_lock_label_2}`]"
                            door="2" />
                        <!-- <button v-if="smartKeys.smart_door_lock_command_2 == 'true'" @click="openDoor(2)" class=" w-100 btn btn-outline-secondary btn-lg mt-3">
                            <span v-if="smartKeys.smart_door_lock_headline_2 != ''">{{ translator.obrir }} {{ smartKeys.smart_door_lock_headline_2 | toLower }}</span>
                            <span v-else>{{ translator.obrir }} {{ smartKeys.smart_door_lock_label_2 | toLower }}</span>
                        </button> -->
                    </div>

                    <div v-if="(smartKeys.smart_door_lock_command_3 == 'true' || smartKeys.smart_door_lock_text_3 != '') && (is_DateCheckinGreaterThan && is_DateCheckoutSmallerThan)"
                        class="card shadow-sm p-3 mb-3 align-items-center">
                        <div>
                            <span><b>{{ smartKeys.smart_door_lock_headline_3 }}</b></span>
                            <div v-html="this.smartKeys.smart_door_lock_text_3"></div>
                        </div>
                        <BotonSlider v-if="smartKeys.smart_door_lock_command_3 == 'true'" :id="'btn3'"
                            :translator="translator" :numBooking="user.user_booking" :userId="userId"
                            :name="[`${smartKeys.smart_door_lock_headline_3}`, `${smartKeys.smart_door_lock_label_3}`]"
                            door="3" />
                        <!-- <button v-if="smartKeys.smart_door_lock_command_3 == 'true'" @click="openDoor(3)" class="w-100 btn btn-outline-secondary btn-lg mt-3">
                            <span v-if="smartKeys.smart_door_lock_headline_3 != ''">{{ translator.obrir }} {{ smartKeys.smart_door_lock_headline_3 | toLower }}</span>
                            <span v-else>{{ translator.obrir }} {{ smartKeys.smart_door_lock_label_3 | toLower }}</span>
                        </button> -->
                    </div>

                    <div v-if="(smartKeys.smart_door_lock_command_4 == 'true' || smartKeys.smart_door_lock_text_4 != '') && (is_DateCheckinGreaterThan && is_DateCheckoutSmallerThan)"
                        class="card shadow-sm p-3 mb-3 align-items-center">
                        <div>
                            <span><b>{{ smartKeys.smart_door_lock_headline_4 }}</b></span>
                            <div v-html="this.smartKeys.smart_door_lock_text_4"></div>
                        </div>
                        <BotonSlider v-if="smartKeys.smart_door_lock_command_4 == 'true'" :id="'btn4'"
                            :translator="translator" :numBooking="user.user_booking" :userId="userId"
                            :name="[`${smartKeys.smart_door_lock_headline_4}`, `${smartKeys.smart_door_lock_label_4}`]"
                            door="4" />
                        <!-- <button v-if="smartKeys.smart_door_lock_command_4 == 'true'" @click="openDoor(4)" class="w-100 btn btn-outline-secondary btn-lg mt-3">
                            <span v-if="smartKeys.smart_door_lock_headline_4 != ''">{{ translator.obrir }} {{ smartKeys.smart_door_lock_headline_4 | toLower }}</span>
                            <span v-else>{{ translator.obrir }} {{ smartKeys.smart_door_lock_label_4 | toLower }}</span>
                        </button> -->
                    </div>

                    <div v-if="(smartKeys.smart_door_lock_command_5 == 'true' || smartKeys.smart_door_lock_text_5 != '') && (is_DateCheckinGreaterThan && is_DateCheckoutSmallerThan)"
                        class="card shadow-sm p-3 mb-3 align-items-center">
                        <div>
                            <span><b>{{ smartKeys.smart_door_lock_headline_5 }}</b></span>
                            <div v-html="this.smartKeys.smart_door_lock_text_5"></div>
                        </div>
                        <BotonSlider v-if="smartKeys.smart_door_lock_command_5 == 'true'" :id="'btn5'"
                            :translator="translator" :numBooking="user.user_booking" :userId="userId"
                            :name="[`${smartKeys.smart_door_lock_headline_5}`, `${smartKeys.smart_door_lock_label_5}`]"
                            door="5" />
                        <!-- <button v-if="smartKeys.smart_door_lock_command_5 == 'true'" @click="openDoor(5)" class="w-100 btn btn-outline-secondary btn-lg mt-3">
                            <span v-if="smartKeys.smart_door_lock_headline_5 != ''">{{ translator.obrir }} {{ smartKeys.smart_door_lock_headline_5 | toLower }}</span>
                            <span v-else>{{ translator.obrir }} {{ smartKeys.smart_door_lock_label_5 | toLower }}</span>
                        </button> -->
                    </div>

                    <!-- URL d'una app externa: tipo Staymyway o Akiles -->
                    <a v-if="(smartKeys.smart_door_lock_app != '') && (is_DateCheckinGreaterThan && is_DateCheckoutSmallerThan)"
                        target="_blank" :href="smartKeys.smart_door_lock_app" class="btn btn-secondary mt-3">
                        {{ translator.obrir_clau_app }}
                    </a>
                </div>

                <!-- Missatge de que veuran el codi de la porta el dia del check-in -->
                <div class="mt-5" style="font-size: 13px; line-height: 18px;">
                    {{ translator.activacio_clau1 }}
                    {{ booking.arrival_date | transformDate }}
                    <span v-if="booking.arrival_time != ''">{{ translator.activacio_clau2 }} {{ booking.arrival_time
                        }}h</span>
                    <span v-else>{{ translator.activacio_clau2 }} {{ booking.lodging_checkin_from }}h</span>
                    {{ translator.activacio_clau3 }}
                    {{ booking.departure_date | transformDate }}
                    <span v-if="booking.departure_time != ''">{{ translator.activacio_clau2 }} {{ booking.departure_time
                        }}h</span>
                    <span v-else>{{ translator.activacio_clau2 }} {{ booking.lodging_checkout_to }}h</span>.
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import NavbarGuest from "@/components/NavbarGuest.vue";
import BotonSlider from "@/components/BotonSlider.vue";
import { mapState } from "vuex";
export default {
    name: "Guest",
    components: {
        NavbarGuest,
        BotonSlider
    },
    computed: {
        ...mapState("utils", ['user', 'translator']),
        ...mapState("guest", ['booking', 'bookingGuests', 'bookingPayments', 'instruccions', 'isNewMessage', 'smartKeys', 'stateDoor']),
    },
    watch: {
        translator() {
            this.$store.dispatch("guest/getBookingID", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
        },
        booking() {
            this.isPreLoad = false;
            this.correctDateCheckin();
            this.correctDateCheckout();
            this.$store.dispatch("guest/getBookingPayments", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            if (this.booking.mandatory_signatures_contract) {
                this.$store.dispatch("guest/getHostes", { usr: String(this.$route.query.id).replace(/[ ]/g, '+'), reserva: this.user.user_booking });
            }
        },
        bookingGuests() {
            let adults = 0;
            let firms = 0;

            this.bookingGuests.length == 0 ? this.isFilledFields = false : null;
            this.bookingGuests.forEach(guest => {
                guest._isAdult ? adults++ : '';
                guest._isAdult && guest._isSign ? firms++ : null;
                guest._input_empty ? this.isFilledFields = false : null;
                guest._isAdult && !guest.document_image ? this.is_allPhotoDocuments = false : null;
                guest._isAdult && !guest.document_photo ? this.is_allAnversoDocument = false : null;
                guest._isAdult && !guest.guest_photo ? this.is_allSelfiGuests = false : null;
            });

            //firms >= adults && this.booking.adults <= adults ? this.controlAdultWithSign = true : null;
            firms >= adults && firms > 0 ? this.controlAdultWithSign = true : null;
        },
        stateDoor() {
            if (this.stateDoor === true) {
                this.$store.dispatch("guest/resetOpenDoor", { state: false });
            }
        }
    },
    async created() {
        try {
            this.isPreLoad = true;
            await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
        } catch (error) {
            console.error(error);
        }
    },
    data() {
        return {
            isPreLoad: false,
            controlAdultWithSign: false,
            is_DateCheckinGreaterThan: false,
            is_DateCheckoutSmallerThan: false,
            is_allSelfiGuests: true,
            is_allPhotoDocuments: true,
            is_allAnversoDocument: true,
            isFilledFields: true,
            titleInstructions: '',
            textInstructions: '',
            userId: String(this.$route.query.id).replace(/[ ]/g, '+')
        };
    },
    methods: {
        correctDateCheckin() {
            const compare = (a, b) => a.getTime() > b.getTime();
            let fechaAhora = new Date();
            let llegada;


            this.booking.arrival_time == '' ? this.booking.arrival_time = this.booking._lodging_checkin_from_show_input : null;
            // Chequeo formato hora
            let convertArrival_time = String(this.booking.arrival_time).length <= 2 ? `${this.booking.arrival_time}:00` : `${this.booking.arrival_time}`;
            let convertLodging_checkin_from = String(this.booking.lodging_checkin_from).length <= 2 ? `${this.booking.lodging_checkin_from}:00` : `${this.booking.lodging_checkin_from}`;

            //.replace(/\s/, 'T') <-- esto es para la fecha en Mac
            this.booking.arrival_time == '' ? llegada = new Date(`${this.booking.arrival_date} ${convertLodging_checkin_from}`.replace(/\s/, 'T')) : llegada = new Date(`${this.booking.arrival_date} ${convertArrival_time}`.replace(/\s/, 'T'))
            this.is_DateCheckinGreaterThan = compare(fechaAhora, llegada)
            console.log(fechaAhora, llegada, this.is_DateCheckinGreaterThan)
        },
        correctDateCheckout() {
            const compare = (a, b) => a.getTime() > b.getTime();
            let fechaAhora = new Date();
            let salida;

            // Chequeo formato hora
            //this.booking.departure_time == '' ? this.booking.departure_time = this.booking._lodging_checkout_to_show_input : null;

            let convertDeparture_time = String(this.booking.departure_time).length <= 2 ? `${this.booking.departure_time}:00` : `${this.booking.departure_time}`;
            let convertLodging_checkout_to = String(this.booking.lodging_checkout_to).length <= 2 ? `${this.booking.lodging_checkout_to}:00` : `${this.booking.lodging_checkout_to}`;

            //.replace(/\s/, 'T') <-- esto es para la fecha en Mac
            this.booking.departure_time == '' ? salida = new Date(`${this.booking.departure_date} ${convertLodging_checkout_to}`.replace(/\s/, 'T')) : salida = new Date(`${this.booking.departure_date} ${convertDeparture_time}`.replace(/\s/, 'T'))

            this.is_DateCheckoutSmallerThan = compare(salida, fechaAhora)
        },
        openDoor(x) {
            this.$store.dispatch("guest/openDoor", {
                usr: String(this.$route.query.id).replace(/[ ]/g, '+'),
                reserva: this.user.user_booking,
                pany: x,
                ip: '',
                vm: this,
                tObert: 'Open'
            });
        },
    },
    filters: {
        toLower(value) {
            if (!value) return '';
            value = value.toString();
            return value.toLowerCase();
        },
        transformDate(value) {
            if (!value) return '';
            const backendDate = value.split('-');
            return `${backendDate[2]}/${backendDate[1]}/${backendDate[0]}`;
        },
    }
};
</script>

<style>
.key-code {
    font-weight: bold;
    font-size: 22px;
}

.btn-outline-secondary:hover {
    border-color: var(--colores-azul-claro) !important;
    color: var(--colores-azul-claro) !important;
    background-color: transparent;
}

.btn:focus {
    box-shadow: none;
}

.btn-link {
    color: #ff7707;
}
</style>