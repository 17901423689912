import postData from '../assets/js/fetch.js'

let disparado = false
let dev
process.env.NODE_ENV === 'production'
  ? (dev = '')
  : (dev = 'https://xapp.icnea.net')

const compare = (a, b) => a.getTime() >= b.getTime()

const formatDate = (date) => {
  const allMonths = JSON.parse(sessionStorage.getItem('diccionario')).monthShort
  const allWeekdays = JSON.parse(sessionStorage.getItem('diccionario'))
    .weekdayShort
  const months = [
    allMonths[0],
    allMonths[1],
    allMonths[2],
    allMonths[3],
    allMonths[4],
    allMonths[5],
    allMonths[6],
    allMonths[7],
    allMonths[8],
    allMonths[9],
    allMonths[10],
    allMonths[11],
  ]
  const week = [
    allWeekdays[0],
    allWeekdays[1],
    allWeekdays[2],
    allWeekdays[3],
    allWeekdays[4],
    allWeekdays[5],
    allWeekdays[6],
  ]

  let long_date = ''
  let day = date.slice(8, 10)
  let month = date.slice(5, 7)
  let year = date.slice(0, 4)
  //console.log(date + ', day: ' + day + ', month: ' + month + ', year: ' + year);
  let new_date = new Date(date)
  let day_week = new_date.getUTCDay()
  long_date = week[day_week] + ', ' + day + ' ' + months[month - 1] + ' ' + year

  return long_date
}
let dateNow = new Date().toISOString().slice(0, 10)

const state = {
  dashboard: [],
  arrivals: [],
  departures: [],
  //calendar: [],
  calendars: [],
  booking: {},
  itRegionBirth: [],
  itDocumentCity: [],
  bookingGuests: [],
  newGuest: '0', /////////////////////////////
  bookingPayments: [],
  bookingContract: [],
}

const mutations = {
  setDashboard(state, dashboard) {
    state.dashboard = dashboard
  },
  setArrivals(state, arrivals) {
    state.arrivals = arrivals
  },
  setDepartures(state, departures) {
    state.departures = departures
  },
  // setCalendar(state, calendar) {
  //     state.calendar = calendar;
  // },
  setCalendars(state, calendars) {
    state.calendars = calendars
  },
  setBooking(state, booking) {
    state.booking = booking
  },
  setItRegionBirth(state, itRegionBirth) {
    state.itRegionBirth = itRegionBirth
  },
  setItDocumentCity(state, itDocumentCity) {
    state.itDocumentCity = itDocumentCity
  },
  setBookingGuests(state, bookingGuests) {
    state.bookingGuests = bookingGuests
  },
  setNewGuest(state, newGuest) {
    //////////////////////////
    state.newGuest = newGuest
  },
  setBookingPayments(state, bookingPayments) {
    state.bookingPayments = bookingPayments
  },
  setBookingContract(state, bookingContract) {
    state.bookingContract = bookingContract
  },
}

const actions = {
  // Inout
  getDashboard({commit}, {usr}) {
    postData(dev + '/WebService.asmx/EntradesSortides', {usr}).then((data) => {
      console.log('EntradesSortides', JSON.parse(data.d).Response)
      commit('setDashboard', JSON.parse(data.d).Response)
    })
  },
  // Arrivals
  getArrivals({commit}, {usr}) {
    postData(dev + '/WebService.asmx/Entrades', {usr}).then((data) => {
      console.log('Entrades', JSON.parse(data.d).Response)
      commit('setArrivals', JSON.parse(data.d).Response)
    })
  },
  // Departures
  getDepartures({commit}, {usr}) {
    postData(dev + '/WebService.asmx/Sortides', {usr}).then((data) => {
      console.log('Sortides', JSON.parse(data.d).Response)
      commit('setDepartures', JSON.parse(data.d).Response)
    })
  },
  // Calendar
  getCalendar({commit}, {usr, data}) {
    postData(dev + '/WebService.asmx/Calendari', {usr, data}).then((data) => {
      console.log('Calendari', JSON.parse(data.d).Response)
      commit('setCalendar', JSON.parse(data.d).Response)
    })
  },
  getCalendars(
    {commit},
    {
      usr,
      datefrom,
      dateto,
      country,
      region,
      group,
      owner,
      properties,
      management,
      typology,
      capacity,
    },
  ) {
    postData(dev + '/WebService.asmx/Calendaris', {
      usr,
      datefrom,
      dateto,
      country,
      region,
      group,
      owner,
      properties,
      management,
      typology,
      capacity,
    }).then((data) => {
      console.log('Calendars', JSON.parse(data.d).Response)
      commit('setCalendars', JSON.parse(data.d).Response)
    })
  },
  // Booking
  getBooking({commit, dispatch}, {usr, reserva}) {
    postData(dev + '/WebService.asmx/ReservaResum', {
      usr,
      reserva,
    }).then((data) => {
      //console.log('ReservaResumRAW', data)
      let reservation = JSON.parse(String(data.d).replace('\\', '/')).Response

      reservation.guests =
        parseInt(reservation.adults) + parseInt(reservation.children)

      // Sumamos bebes si es formato portugués
      if (reservation.format_hostes == 'PT') {
        reservation.sumAdultsChildren =
          parseInt(reservation.adults) +
          parseInt(reservation.children) +
          parseInt(reservation.babies)
        if (
          parseInt(reservation.registered_guests) ===
          parseInt(reservation.adults) +
            parseInt(reservation.children) +
            parseInt(reservation.babies)
        ) {
          reservation.guestsCompleted = true
        } else {
          reservation.guestsCompleted = false
        }
      } else {
        reservation.sumAdultsChildren =
          parseInt(reservation.adults) + parseInt(reservation.children)
        if (
          parseInt(reservation.registered_guests) ===
          parseInt(reservation.adults) + parseInt(reservation.children)
        ) {
          reservation.guestsCompleted = true
        } else {
          reservation.guestsCompleted = false
        }
      }

      if (
        parseInt(reservation.registered_guests) >= parseInt(reservation.adults)
      ) {
        reservation._registered_adults_only_completed = true
      } else {
        reservation._registered_adults_only_completed = false
      }

      if (parseFloat(reservation.pending_payment) <= 0) {
        //reservation.pending_payment_status = this.translator.pagat;
        reservation.paymentCompleted = true
      } else {
        reservation.pending_payment_status =
          reservation.pending_payment + reservation.currency
        reservation.paymentCompleted = false
      }

      if (reservation.signed_contract === 'true') {
        reservation.signed_contract_status = true //this.translator.signat
        reservation.contractCompleted = true
      } else {
        reservation.signed_contract_status = false //this.translator.nosignat
        reservation.contractCompleted = false
      }

      if (reservation.status === '6') {
        reservation.checkinCompleted = true
      } else {
        reservation.checkinCompleted = false
      }

      reservation.checkOutInTime = compare(
        new Date(dateNow),
        new Date(reservation.departure_date),
      )

      reservation._arrival_date_format = formatDate(reservation.arrival_date)
      reservation._departure_date_format = formatDate(
        reservation.departure_date,
      )
      reservation._image_format = `background-image: url(${String(
        reservation.image,
      )}); 
                                                background-size: cover; 
                                                background-repeat: no-repeat; 
                                                height: 150px;
                                                background-position: center;`

      // Seteo de horas
      if (reservation.arrival_time != '') {
        String(reservation.arrival_time).length <= 2
          ? (reservation._arrival_time = `${reservation.arrival_time}:00`)
          : (reservation._arrival_time = `${reservation.arrival_time}`)
      } else {
        reservation._arrival_time = reservation.arrival_time
      }

      if (reservation.departure_time != '') {
        String(reservation.departure_time).length <= 2
          ? (reservation._departure_time = `${reservation.departure_time}:00`)
          : (reservation._departure_time = `${reservation.departure_time}`)
      } else {
        reservation._departure_time = reservation.departure_time
      }

      // Entrada desde
      parseInt(reservation.lodging_checkin_from) == 24
        ? (reservation._lodging_checkin_from_show_input = 0)
        : (reservation._lodging_checkin_from_show_input = parseInt(
            reservation.lodging_checkin_from,
          ))
      // Entrada hasta
      parseInt(reservation.lodging_checkin_to) == 0
        ? (reservation._lodging_checkin_to_show_input = 24)
        : (reservation._lodging_checkin_to_show_input = parseInt(
            reservation.lodging_checkin_to,
          ))

      // Si los campos de entrada del PMS estan vacios muestro todas las horas
      if (
        reservation._lodging_checkin_from_show_input == 0 &&
        reservation._lodging_checkin_to_show_input == 24
      ) {
        reservation._lodging_checkin_from_show_input = 16
        reservation._lodging_checkin_to_show_input = 20
      }

      // Salida desde
      parseInt(reservation.lodging_checkout_from) == 24
        ? (reservation._lodging_checkout_from_show_input = 0)
        : (reservation._lodging_checkout_from_show_input = parseInt(
            reservation.lodging_checkout_from,
          ))
      // Salida hasta
      parseInt(reservation.lodging_checkout_to) == 0
        ? (reservation._lodging_checkout_to_show_input = 24)
        : (reservation._lodging_checkout_to_show_input = parseInt(
            reservation.lodging_checkout_to,
          ))

      // Si los campos de salida del PMS estan vacios muestro todas las horas
      if (
        reservation._lodging_checkout_from_show_input == 0 &&
        reservation._lodging_checkout_to_show_input == 24
      ) {
        reservation._lodging_checkout_from_show_input = 9
        reservation._lodging_checkout_to_show_input = 12
      }

      commit('setBooking', reservation)
      console.log('ReservaResum', reservation)

      // Si tiene llave
      if (reservation.smart_door_lock == 'true') {
        dispatch('getReservaPany', {usr, reserva: reservation.reservation})
      }
    })
  },
  postBookingSave(
    {dispatch},
    {
      usr,
      reservation,
      language,
      nom,
      cognom,
      adreca,
      poble,
      dip,
      pais,
      telefon,
      email,
      cif,
      nacionalitat,
      adults,
      joves,
      bebes,
      observacions,
      numvol,
      horaentrada,
      horasortida,
    },
  ) {
    postData(dev + '/WebService.asmx/ReservaGuardar', {
      usr,
      reservation,
      language,
      nom,
      cognom,
      adreca,
      poble,
      dip,
      pais,
      telefon,
      email,
      cif,
      nacionalitat,
      adults,
      joves,
      bebes,
      observacions,
      numvol,
      horaentrada,
      horasortida,
    }).then((data) => {
      console.log('ReservaGuardar', JSON.parse(data.d))
      if (JSON.parse(data.d) === true) {
        dispatch('getBooking', {usr, reserva: reservation})
      }
    })
  },
  postAddGuest(
    {dispatch},
    {
      usr,
      reserva,
      hostId,
      nom,
      cognom1,
      cognom2,
      sexe,
      tipus,
      naixement_data,
      naixement_pais,
      naixement_lloc,
      naixement_regio,
      document_numero,
      document_suport,
      document_tipus,
      document_data,
      document_pais,
      document_lloc,
      adreca_pais,
      adreca_lloc,
      adreca_poble,
      adreca_dip,
    },
  ) {
    if (!disparado) {
      disparado = true
      postData(dev + '/WebService.asmx/HostesGuardar', {
        usr,
        reserva,
        hostId,
        nom,
        cognom1,
        cognom2,
        sexe,
        tipus,
        naixement_data,
        naixement_pais,
        naixement_lloc,
        naixement_regio,
        document_numero,
        document_suport,
        document_tipus,
        document_data,
        document_pais,
        document_lloc,
        adreca_pais,
        adreca_lloc,
        adreca_poble,
        adreca_dip,
      }).then((data) => {
        console.log('HostesGuardar', JSON.parse(data.d))
        if (JSON.parse(data.d) === true) {
          dispatch('getBooking', {usr, reserva})
          dispatch('getHostes', {usr, reserva})
        }
      })

      setTimeout(() => {
        disparado = false
      }, 1000)
    }
  },
  getHostesRegionsIT({commit}, {usr, reserva}) {
    postData(dev + '/WebService.asmx/HostesRegionsIT', {usr, reserva}).then(
      (data) => {
        console.log('HostesRegionsIT', JSON.parse(data.d).Response)
        commit('setItRegionBirth', JSON.parse(data.d).Response)
      },
    )
  },
  getHostesPoblesIT({commit}, {usr, reserva}) {
    postData(dev + '/WebService.asmx/HostesPoblesIT', {usr, reserva}).then(
      (data) => {
        console.log('HostesPoblesIT', JSON.parse(data.d).Response)
        commit('setItDocumentCity', JSON.parse(data.d).Response)
      },
    )
  },
  getHostes({commit}, {usr, reserva}) {
    postData(dev + '/WebService.asmx/Hostes', {usr, reserva}).then((data) => {
      // Comprobación de los campos obligatorios de cada huésped
      let people = JSON.parse(data.d).Response
      people.forEach((guests) => {
        let isCorrect = true
        //let isSpanish = true;

        //guests.document_country === 'ES' ? isSpanish = true : isSpanish = false;
        guests._isAdult = isAdult(guests.guest_birthday_date)
        guests.guest_signature == ''
          ? (guests._isSign = false)
          : (guests._isSign = true)

        for (const x in guests) {
          x === 'guest_first_name' && guests[x] == '' ? (isCorrect = false) : ''
          x === 'guest_last_name_one' && guests[x] == ''
            ? (isCorrect = false)
            : ''
          // if (isSpanish) {
          //     x === "guest_last_name_two" && guests[x] == '' ? isCorrect = false : '';
          // }
          x === 'guest_sex' && guests[x] == '' ? (isCorrect = false) : ''
          x === 'guest_birthday_date' && guests[x] == ''
            ? (isCorrect = false)
            : ''
          x === 'document_number' && guests[x] == '' ? (isCorrect = false) : ''
          x === 'document_type' && guests[x] == '' ? (isCorrect = false) : ''
          x === 'document_country' && guests[x] == '' ? (isCorrect = false) : ''
          x === 'document_date' && guests[x] == '' ? (isCorrect = false) : ''
        }
        isCorrect ? (guests._input_empty = false) : (guests._input_empty = true)
      })

      function isAdult(fecha) {
        let hoy = new Date()
        let birth = new Date(fecha)
        let edad = hoy.getFullYear() - birth.getFullYear()
        let diferenciaMeses = hoy.getMonth() - birth.getMonth()
        if (
          diferenciaMeses < 0 ||
          (diferenciaMeses === 0 && hoy.getDate() < birth.getDate())
        ) {
          edad--
        }
        let isAdult = false
        edad >= 18 ? (isAdult = true) : (isAdult = false)
        return isAdult
      }

      commit('setBookingGuests', people)
      console.log('Hostes', people)
    })
  },
  deleteHostesEliminar({dispatch}, {usr, reserva, hostId}) {
    postData(dev + '/WebService.asmx/HostesEliminar', {
      usr,
      reserva,
      hostId,
    }).then((data) => {
      if (data.d === 'True') {
        dispatch('getBooking', {usr, reserva})
        dispatch('getHostes', {usr, reserva})
      }
    })
  },
  checkIn({dispatch}, {usr, reserva, ip}) {
    postData(dev + '/WebService.asmx/Checkin', {usr, reserva, ip}).then(
      (data) => {
        console.log('Checkin', data)
        dispatch('getBooking', {usr, reserva})
      },
    )
  },
  checkOut({dispatch}, {usr, reserva, ip}) {
    postData(dev + '/WebService.asmx/Checkout', {usr, reserva, ip}).then(
      (data) => {
        console.log('Checkout', data)
        dispatch('getBooking', {usr, reserva})
      },
    )
  },
  // Lectura DNI
  postPhotoDNI({dispatch, commit}, {usr, reserva, hostId, base64}) {
    postData(dev + '/WebService.asmx/HostesPassaport', {
      usr,
      reserva,
      hostId,
      base64,
    }).then((data) => {
      commit('setNewGuest', data.d)
      dispatch('getBooking', {usr, reserva})
      dispatch('getHostes', {usr, reserva})
    })
  },
  //Firma huésped
  postSignGuest({dispatch}, {usr, reserva, hostId, base64}) {
    postData(dev + '/WebService.asmx/HostesSignatura', {
      usr,
      reserva,
      hostId,
      base64,
    }).then((data) => {
      console.log('HostesSignatura', data.d)
      if (data.d == true) {
        dispatch('getBooking', {usr, reserva})
        dispatch('getHostes', {usr, reserva})
      }
    })
  },
  // Foto anverso y reverso DNI posición 1 = reverso 2 = anverso
  postSavePhotoFrontPassport(
    {dispatch},
    {usr, reserva, hostId, posicio, base64},
  ) {
    postData(dev + '/WebService.asmx/HostesPassaportFoto', {
      usr,
      reserva,
      hostId,
      posicio,
      base64,
    }).then((data) => {
      data.d == true ? dispatch('getHostes', {usr, reserva}) : ''
    })
  },
  // Foto selfie
  postSaveSelfie({dispatch}, {usr, reserva, hostId, base64}) {
    postData(dev + '/WebService.asmx/HostesFoto', {
      usr,
      reserva,
      hostId,
      base64,
    }).then((data) => {
      data.d == true ? dispatch('getHostes', {usr, reserva}) : ''
    })
  },

  // Booking_payment
  getBookingPayments({commit}, {usr, reserva}) {
    postData(dev + '/WebService.asmx/ReservaDetall', {usr, reserva}).then(
      (data) => {
        console.log('RawReservaDetall', data)
        console.log('ReservaDetall', JSON.parse(data.d).Response)
        commit('setBookingPayments', JSON.parse(data.d).Response)
      },
    )
  },
  postCardPayment({dispatch}, {usr, reserva, cobrar, diposit, paypal}) {
    postData(dev + '/WebService.asmx/Banc', {
      usr,
      reserva,
      cobrar,
      diposit,
      paypal,
    }).then((data) => {
      console.log('Banc', data.d)
      if (data.d != '') {
        dispatch('getBookingPayments', {usr, reserva})
        window.location.href = data.d
      }
    })
  },
  postCashPayment(
    {dispatch},
    {usr, reserva, importPagar, tipus, data, observacions},
  ) {
    postData(dev + '/WebService.asmx/ReservaPagament', {
      usr,
      reserva,
      import: importPagar,
      tipus,
      data,
      observacions,
    }).then((data) => {
      console.log('ReservaPagament', data.d)
      dispatch('getBookingPayments', {usr, reserva})
    })
  },

  // Booking_contract
  getBookingContract({commit}, {usr, reserva}) {
    postData(dev + '/WebService.asmx/Contracte', {usr, reserva})
      .then((data) => {
        if (data.d != '') {
          console.log('Contracte', JSON.parse(data.d).Response)
          commit('setBookingContract', JSON.parse(data.d).Response)
        } else {
          console.log('ContracteRaw', data)
          commit('setBookingContract', '')
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  },
  postContracteSignatura({dispatch}, {usr, reserva, jpg, ip, language}) {
    postData(dev + '/WebService.asmx/ContracteSignatura', {
      usr,
      reserva,
      jpg,
      ip,
    }).then((data) => {
      console.log('ContracteSignatura', JSON.parse(data.d).Response)
      dispatch('getBookingContract', {usr, reserva, language})
    })
  },

  //Cargo checkin por llegar tarde
  postCheckinCarrec({dispatch}, {usr, reserva, carrec, ip}) {
    postData(dev + '/WebService.asmx/CheckinCarrec', {
      usr,
      reserva,
      import: carrec,
      ip,
    }).then((data) => {
      console.log('CheckinCarrec', data.d)
      data.d ? dispatch('getBookingPayments', {usr, reserva}) : ''
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
